import React from 'react';
import {ScrollableFlex, WrapperFlex} from '../../utils/scrolling';
import {
  TableContainer,
  Table,
  Text,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  HStack,
  Spacer,
  Box,
  Button,
  Tag,
  useDisclosure,
  Tooltip,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import {
  useDeleteModelTestGroup,
  useGetModelTestDefinition,
  useGetModelTestGroupResults,
  useGetModelTestResults,
} from '../../../hooks/api/scoringModel';
import {useMarketFromContext} from '../MarketProvider';
import _ from 'lodash';
import {ValidateDomainsButton} from './ValidateDomainsButton';
import {useIsKeyplayAdmin} from '../../../hooks/api/user';
import {ModelTestGroup} from '../../../shared/modelTest';
import {
  ModelTestResults,
  ModelTestSignalCounts,
  TierCounts,
} from '../../../shared/scoring';
import {ObjectId} from 'bson';
import {TierPieChart} from './TierPieChart';
import {AccountTier} from '../../../shared/scoredAccounts';
import {UseQueryResult} from '@tanstack/react-query';
import {DelayedSpinner} from '../../DelayedSpinner';
import {ModelTestGroupImportModal} from './ModelTestGroupImport';
import {MaxModelTestGroups} from '../../../shared/api/definitions';
import {TrashCan} from '@carbon/icons-react';
import {Parser} from '@json2csv/plainjs';
import FileSaver from 'file-saver';
import {useCustomer} from '../../../hooks/api/metadata';
import {useScoringSignalResolver} from '../../../hooks/scoringSignal';
import {ScoringSignalResolver} from '../../../shared/signals';

const GroupResultsQueryContext =
  React.createContext<UseQueryResult<TierCounts> | null>(null);
const useGroupResultsQuery = () => React.useContext(GroupResultsQueryContext);

const GroupResultsQueryProvider = ({
  groupId,
  children,
}: React.PropsWithChildren<{groupId: ObjectId}>) => {
  const {id: marketId} = useMarketFromContext();
  const query = useGetModelTestGroupResults({
    groupId: groupId.toString(),
    marketId: marketId.toString(),
  });

  return (
    <GroupResultsQueryContext.Provider value={query}>
      {children}
    </GroupResultsQueryContext.Provider>
  );
};

const ConditionalGroupQueryProvider = ({
  group,
  children,
}: React.PropsWithChildren<{group: ModelTestGroup}>) => {
  if (!group.id || group.status === 'pending') {
    return <>{children}</>;
  }

  return (
    <GroupResultsQueryProvider groupId={group.id}>
      {children}
    </GroupResultsQueryProvider>
  );
};

const TierCell = ({tier}: {tier: AccountTier}) => {
  const query = useGroupResultsQuery();
  const counts = query?.data;

  if (!query) {
    return <></>;
  }

  if (!counts || query.isFetching) {
    return (
      <Skeleton
        isLoaded={false}
        style={{width: 'auto', display: 'inline-block'}}
      >
        00.00%
      </Skeleton>
    );
  }

  const count = counts[tier];
  const sum = _.sum(Object.values(counts));
  const percentage = ((count / sum) * 100).toFixed(2);

  return <>{percentage}%</>;
};

const DeleteCell = ({groupId}: {groupId: ObjectId}) => {
  const deleteGroup = useDeleteModelTestGroup();
  const {id: marketId} = useMarketFromContext();
  return (
    <Button
      key="delete"
      colorScheme="red"
      variant="outline"
      isDisabled={deleteGroup.isLoading}
      onClick={() => deleteGroup.mutate({groupId, marketId})}
    >
      <TrashCan />
    </Button>
  );
};

const GroupStatus = ({group}: {group: ModelTestGroup}) => {
  const query = useGroupResultsQuery();
  const tierCounts = query?.data;

  return group.status === 'pending' ? (
    <Tag
      key="default"
      size={'md'}
      fontWeight={500}
      fontSize={12}
      textTransform="uppercase"
    >
      Processing
    </Tag>
  ) : tierCounts && !query?.isFetching ? (
    <TierPieChart tierCounts={tierCounts} />
  ) : (
    <></>
  );
};

const columnHelper = createColumnHelper<ModelTestGroup>();
const columns = [
  columnHelper.accessor('label', {
    id: 'name',
    header: 'List Name',
  }),
  columnHelper.accessor((row) => row, {
    id: 'pie',
    header: '',
    cell: (info) => <GroupStatus group={info.getValue()} />,
    size: 125,
  }),
  columnHelper.accessor('status', {
    id: 'total',
    header: 'Num Accounts',
    cell: function Cell(info) {
      const tierCounts = useGroupResultsQuery()?.data;

      if (info.getValue() === 'pending') {
        return <></>;
      }

      return tierCounts ? (
        _.sum(Object.values(tierCounts))
      ) : (
        <Skeleton
          isLoaded={false}
          style={{width: 'auto', display: 'inline-block'}}
        >
          000
        </Skeleton>
      );
    },
    size: 125,
  }),
  columnHelper.accessor((row) => row, {
    id: 'tierA',
    header: 'A',
    cell: () => <TierCell tier="A" />,
    size: 125,
  }),
  columnHelper.accessor((row) => row, {
    id: 'tierB',
    header: 'B',
    cell: () => <TierCell tier="B" />,
    size: 125,
  }),
  columnHelper.accessor((row) => row, {
    id: 'tierC',
    header: 'C',
    cell: () => <TierCell tier="C" />,
    size: 125,
  }),
  columnHelper.accessor((row) => row, {
    id: 'tierD',
    header: 'D',
    cell: () => <TierCell tier="D" />,
    size: 125,
  }),
  columnHelper.accessor('id', {
    header: '',
    id: 'delete',
    size: 50,
    cell: (info) => <DeleteCell groupId={info.getValue()} />,
  }),
];

const ModelTestTable = ({groups}: {groups: ModelTestGroup[]}) => {
  const table = useReactTable({
    columns,
    data: groups,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <ConditionalGroupQueryProvider key={row.id} group={row.original}>
              <Tr role="group" height="100px">
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    _groupHover={{bg: 'kgray.100'}}
                    width={
                      cell.column.getSize()
                        ? `${cell.column.getSize()}px`
                        : 'auto'
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            </ConditionalGroupQueryProvider>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const downloadResultsCsv = async (results: ModelTestResults, name: string) => {
  const csv = new Parser().parse(
    results.map(({domain, score, tier, groups}) => {
      return {
        Domain: domain,
        'Overall Fit': score.toFixed(2),
        Tier: tier,
        'Test Lists': groups.join(', '),
      };
    })
  );
  const blob = new Blob([csv], {type: 'text/csv'});
  FileSaver.saveAs(blob, `testResults-${name}`);
};

const downloadSignalCountsCsv = async (
  counts: ModelTestSignalCounts,
  name: string,
  resolver: ScoringSignalResolver
) => {
  if (!counts) {
    return;
  }

  const csv = new Parser().parse(
    counts.map(({signal, groupCounts}) => {
      const resolvedSignal = resolver(signal);

      return {
        'Signal Category': resolvedSignal?.category ?? 'unknown',
        'Signal Name': resolvedSignal?.label ?? signal,
        ...groupCounts,
      };
    })
  );
  const blob = new Blob([csv], {type: 'text/csv'});
  FileSaver.saveAs(blob, `signalCounts-${name}`);
};

export const ModelTest = () => {
  const resolver = useScoringSignalResolver();
  const customer = useCustomer();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const isAdmin = useIsKeyplayAdmin();
  const {id: marketId, label: marketLabel} = useMarketFromContext();
  const {data, isInitialLoading} = useGetModelTestDefinition({
    marketId: marketId.toString(),
  });
  const [resultsType, setResultsType] = React.useState<
    'results' | 'counts' | null
  >(null);

  const {groups} = data ?? {};
  const tooManyGroups = (groups?.length ?? 0) >= MaxModelTestGroups;

  const {refetch: fetchModelTestResults, isFetching: fetchingModelTestResults} =
    useGetModelTestResults(
      {
        marketId: marketId.toString(),
      },
      {
        enabled: false,
        onSuccess(data) {
          const label = `${customer.name}-${marketLabel}`;
          if (resultsType === 'results') {
            downloadResultsCsv(data.results, label);
          } else if (resultsType === 'counts') {
            downloadSignalCountsCsv(data.counts, label, resolver);
          }
          setResultsType(null);
        },
      }
    );

  const pendingGroups = data?.groups.some(
    (group) => group.status === 'pending'
  );

  return (
    <>
      <WrapperFlex>
        <ScrollableFlex px={6}>
          <HStack my={4}>
            {isAdmin && (
              <ValidateDomainsButton>Validate Domains</ValidateDomainsButton>
            )}
            {isAdmin && (
              <>
                <Tooltip
                  isDisabled={!tooManyGroups}
                  label={`Maximum of ${MaxModelTestGroups} groups allowed`}
                >
                  <Button
                    isDisabled={isInitialLoading || tooManyGroups}
                    onClick={onOpen}
                    colorScheme="kbuttonblue"
                    fontSize="sm"
                    fontWeight="normal"
                    variant="outline"
                  >
                    Add Test List
                  </Button>
                </Tooltip>
              </>
            )}
            {data?.groups.length && (
              <Menu>
                <Tooltip
                  isDisabled={!pendingGroups}
                  label="Please wait for lists to finish processing"
                >
                  <MenuButton
                    as={Button}
                    isDisabled={pendingGroups}
                    colorScheme="kbuttonblue"
                    fontSize="sm"
                    fontWeight="normal"
                    isLoading={fetchingModelTestResults}
                    variant="outline"
                  >
                    Download
                  </MenuButton>
                </Tooltip>

                <MenuList zIndex={2} minWidth={32}>
                  <MenuItem
                    onClick={() => {
                      setResultsType('results');
                      fetchModelTestResults();
                    }}
                    pl={2}
                  >
                    Results
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setResultsType('counts');
                      fetchModelTestResults();
                    }}
                    pl={2}
                  >
                    Signal Counts
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>
          <Box>
            {isInitialLoading && (
              <HStack>
                <Spacer />
                <DelayedSpinner />
              </HStack>
            )}
            <Box mt={4}>
              {!isInitialLoading &&
                (groups?.length ? (
                  <ModelTestTable groups={groups} />
                ) : (
                  <HStack>
                    <Text>Add some groups to run a test.</Text>
                  </HStack>
                ))}
            </Box>
          </Box>
        </ScrollableFlex>
      </WrapperFlex>
      <ModelTestGroupImportModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
