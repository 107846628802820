import {ObjectId} from 'bson';
import _ from 'lodash';
import {RoleSearchCountrySchema} from './countries';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {zodEnum} from './util';

export type EnrichedFields = Record<string, unknown>;

export const FieldDataTypes = ['number', 'string'] as const;
const FieldDataTypeSchema = zodEnum(FieldDataTypes);

const BaseFieldDefinitionSchema = z.object({
  id: z.instanceof(ObjectId),
  label: z.string(),
  dataType: FieldDataTypeSchema,
  timestamp: z.date(),

  // overridden by subtypes
  config: z.object({}),
  type: z.string(),
});

const RoleCountFieldDefinitionSchema = BaseFieldDefinitionSchema.extend({
  type: z.literal('roleCount'),
  config: z.object({
    include: z.array(z.string()),
    exclude: z.array(z.string()),
    titles: z.array(z.string()),
    countries: z.array(RoleSearchCountrySchema),
  }),
});
export type RoleCountFieldDefinition = z.infer<
  typeof RoleCountFieldDefinitionSchema
>;

export const CrmFieldDefinitionSchema = BaseFieldDefinitionSchema.extend({
  type: z.literal('crm'),
  config: z.object({
    fieldName: z.string(),
    integrationId: z.instanceof(ObjectId),
  }),
});
export type CrmFieldDefinition = z.infer<typeof CrmFieldDefinitionSchema>;
export const isCrmFieldDefinition = zodTypeguard(CrmFieldDefinitionSchema);

const AnalysisFieldSchema = z.object({
  type: z.literal('gpt'),
  name: z.union([
    z.literal('businessAndMarket'),
    z.literal('historyAndCurrentState'),
  ]),
});
export type AnalysisField = z.infer<typeof AnalysisFieldSchema>;

const AiFieldDefinitionConfigBaseSchema = z.object({
  analysisFields: AnalysisFieldSchema.array(),
  model: z.union([z.literal('gpt-4o'), z.literal('gpt-4o-mini')]),
  prompt: z.string(),
});
const AiFieldDefinitionBaseSchema = BaseFieldDefinitionSchema.extend({
  type: z.literal('ai'),
  config: AiFieldDefinitionConfigBaseSchema,
});

const AiBooleanFieldDefinitionSchema = AiFieldDefinitionBaseSchema.extend({
  dataType: z.literal('boolean'),
  config: AiFieldDefinitionConfigBaseSchema.extend({
    fieldType: z.literal('boolean'),
  }),
});

const AiRatingFieldDefinitionSchema = AiFieldDefinitionBaseSchema.extend({
  dataType: z.literal('number'),
  config: AiFieldDefinitionConfigBaseSchema.extend({
    fieldType: z.literal('rating'),
    min: z.number(),
    max: z.number(),
  }),
});

const AiFieldDefinitionSchema = z.union([
  AiBooleanFieldDefinitionSchema,
  AiRatingFieldDefinitionSchema,
]);
export type AiFieldDefinition = z.infer<typeof AiFieldDefinitionSchema>;

const FieldDefinitionSchema = z.union([
  RoleCountFieldDefinitionSchema,
  CrmFieldDefinitionSchema,
  AiFieldDefinitionSchema,
]);

export type FieldDefinition = z.infer<typeof FieldDefinitionSchema>;

export const FieldDefinitionTypeLabels: Record<
  FieldDefinition['type'],
  string
> = {
  ai: 'AI Field',
  crm: 'CRM Field',
  roleCount: 'Role Count',
};

export interface CustomEnrichmentRun {
  customerId: ObjectId;
  fieldDefinition: FieldDefinition;
  expectedNumberOfEnrichedAccounts: number;
  actualNumberOfEnrichedAccounts: number;
  timestamp: Date;
}

// HACK: we don't currently have a universal concept of a parent EnrichmentFieldAction.
// So in the meantime we just use this as a way to group enrichment values that were
// generated together. https://github.com/keyplay-io/bfdb/pull/1572 has some discussion
// and prototyping around what we might want to implement in the future
export type EnrichmentSource =
  | {type: 'enrichmentRun'; id: ObjectId}
  | {type: 'api'; id: ObjectId};

export interface EnrichedFieldValue {
  customerId: ObjectId;
  accountId: ObjectId;
  fieldDefinitionId: ObjectId;

  source: EnrichmentSource;

  timestamp: Date;
  value: unknown;
}
