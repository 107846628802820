import React from 'react';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  FieldDefinition,
  FieldDefinitionTypeLabels,
} from '../../shared/enrichment';
import {useCustomer} from '../../hooks/api/metadata';
import {pluralize} from '../../lib/helpers';
import {useLatestScoringRun} from '../../hooks/api/scoringRun';
import {ChevronDown, OverflowMenuVertical} from '@carbon/icons-react';
import {useIsKeyplayAdmin} from '../../hooks/api/user';
import {CrmFieldModal} from './CrmFieldModal';
import {useEnabledIntegration} from '../../hooks/api/integrations';
import {DeleteFieldModal} from './DeleteFieldModal';

export const CustomFields = () => {
  const customer = useCustomer();
  const fieldDefinitions = (customer.fieldDefinitions ?? []).sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const isKeyplayAdmin = useIsKeyplayAdmin();
  const integration = useEnabledIntegration();
  const crmFieldModal = useDisclosure();

  return (
    <Flex direction="column" gap="4">
      <Flex justifyContent="space-between">
        <Box>
          <Text fontWeight="500">Custom Fields</Text>
          <Text textColor="kgray.300" fontSize="sm">
            {pluralize(fieldDefinitions.length, 'field', 'fields')}
          </Text>
        </Box>
        {isKeyplayAdmin && (
          <Menu>
            <MenuButton
              as={Button}
              colorScheme="kbuttonblue"
              fontWeight="normal"
              rightIcon={<ChevronDown />}
            >
              Create Field
            </MenuButton>
            <MenuList>
              <MenuItem
                isDisabled={!integration}
                onClick={crmFieldModal.onOpen}
              >
                From CRM Field
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
      <CustomFieldsList fieldDefinitions={fieldDefinitions} />
      <CrmFieldModal
        isOpen={crmFieldModal.isOpen}
        onClose={crmFieldModal.onClose}
      />
    </Flex>
  );
};

const columnHelper = createColumnHelper<FieldDefinition>();

const CustomFieldMenu = ({
  fieldDefinition,
}: {
  fieldDefinition: FieldDefinition;
}) => {
  const {onCopy} = useClipboard(fieldDefinition.id.toString());
  const toast = useToast();
  const deleteFieldModal = useDisclosure();

  const copyFieldId = () => {
    toast({
      title: 'Field ID copied to clipboard',
      duration: 1_000,
    });
    onCopy();
  };

  return (
    <Menu>
      <MenuButton>
        <OverflowMenuVertical />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={copyFieldId}>Copy Field ID</MenuItem>
        <MenuItem onClick={deleteFieldModal.onOpen} textColor="kred.500">
          Delete Field
          <DeleteFieldModal
            fieldDefinition={fieldDefinition}
            isOpen={deleteFieldModal.isOpen}
            onClose={deleteFieldModal.onClose}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const columns = [
  columnHelper.accessor('label', {
    header: 'Field Name',
    cell: (info) => {
      return <Box>{info.getValue()}</Box>;
    },
    size: 0,
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    cell: (info) => {
      return (
        <Box textColor="kgray.300">
          {FieldDefinitionTypeLabels[info.getValue()]}
        </Box>
      );
    },
  }),
  columnHelper.accessor('id', {
    header: 'Accounts Enriched',
    cell: function Cell(info) {
      const {data: scoringRun} = useLatestScoringRun({});

      const count =
        scoringRun?.enrichedFieldCounts[
          info.getValue().toString()
        ]?.toLocaleString();
      return (
        <Box textColor="kgray.300" textAlign="end" w="80%">
          {count ?? '-'}
        </Box>
      );
    },
  }),
  columnHelper.accessor('timestamp', {
    header: 'Created',
    size: 100,
    cell: (info) => {
      return (
        <Box textColor="kgray.300">{info.getValue().toLocaleDateString()}</Box>
      );
    },
  }),
  columnHelper.accessor((field) => field, {
    id: 'actions',
    header: '',
    size: 50,
    cell: (info) => {
      return <CustomFieldMenu fieldDefinition={info.getValue()} />;
    },
  }),
];

const CustomFieldsList = ({
  fieldDefinitions,
}: {
  fieldDefinitions: FieldDefinition[];
}) => {
  const table = useReactTable({
    columns,
    data: fieldDefinitions,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 150,
    },
  });

  if (!fieldDefinitions.length) {
    return (
      <Flex direction="column" align="center" p={8}>
        <Text textColor="kgray.300">No custom fields defined.</Text>
      </Flex>
    );
  }

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  w={header.getSize() ? `${header.getSize()}px` : 'auto'}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} role="group">
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  _groupHover={{bg: 'kgray.100'}}
                  w={
                    cell.getContext().column.getSize()
                      ? `${cell.getContext().column.getSize()}px`
                      : 'auto'
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
