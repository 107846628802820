import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  TextProps,
} from '@chakra-ui/react';
import {Operator, useCreateSignalModalStore} from './CreateSignalModal.state';
import {useCustomer} from '../../hooks/api/metadata';
import Select from 'react-select';
import {ScoringCategories, ScoringCategoryLabels} from '../../shared/signals';
import {assertNever} from '../../shared/util';
import _ from 'lodash';
import {useCreateSignalDefinition} from '../../hooks/api/signalDefinitions';

export const CreateSignalModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {reset} = useCreateSignalModalStore();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={reset}
      isCentered
      size="3xl"
    >
      <ModalOverlay />
      <CreateSignalModalContent onClose={onClose} />
    </Modal>
  );
};

const CreateSignalModalContent = ({onClose}: {onClose: () => void}) => {
  const {step} = useCreateSignalModalStore();
  return (
    <ModalContent>
      <ModalCloseButton />
      {step === 'selectField' && <SelectFieldStep />}
      {step === 'defineSignal' && <DefineSignalStep onClose={onClose} />}
    </ModalContent>
  );
};

const SelectFieldStep = () => {
  const {selectedField, setSelectedField, setStep} =
    useCreateSignalModalStore();
  const customer = useCustomer();
  const options =
    customer.fieldDefinitions
      ?.filter(({dataType}) => dataType === 'number')
      ?.map((field) => ({
        value: field.id,
        label: field.label,
        field,
      })) ?? [];

  return (
    <>
      <ModalHeader fontWeight="normal">
        <Text fontSize="xl" mb="1">
          Select Signal Source
        </Text>
        <Text fontSize="sm" textColor="kgray.300">
          Learn more about how to create custom signals{' '}
          <Link
            href="https://docs.keyplay.io/en/"
            isExternal
            textColor="kblue.300"
          >
            here
          </Link>
          .
        </Text>
      </ModalHeader>
      <ModalBody minH="300px">
        <Grid
          templateColumns="1fr 200px"
          columnGap="8"
          rowGap="2"
          alignItems="baseline"
        >
          <GridItem>
            <Label>Field</Label>
          </GridItem>
          <GridItem>
            <Label>Field Type</Label>
          </GridItem>

          <GridItem>
            <Select
              isMulti={false}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }

                setSelectedField(newValue.field);
              }}
              options={options}
              placeholder="Select a Field"
              value={options.find(
                ({value}) => value.toString() === selectedField?.id.toString()
              )}
            />
          </GridItem>
          <GridItem>{selectedField?.dataType ?? '-'}</GridItem>
        </Grid>
      </ModalBody>
      <ModalFooter
        borderTop="1px solid"
        borderColor="kgray.200"
        justifyContent="start"
      >
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          isDisabled={!selectedField}
          onClick={() => setStep('defineSignal')}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
};

const Label = ({children, ...props}: React.PropsWithChildren<TextProps>) => (
  <Text
    fontSize="sm"
    textColor="kgray.300"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </Text>
);

const operatorOptions: {value: Operator; label: string}[] = [
  {
    value: 'between',
    label: 'Is between',
  },
  {
    value: 'greaterThan',
    label: 'Is >=',
  },
  {
    value: 'lessThan',
    label: 'Is <=',
  },
] as const;

const RangeSignalBuilder = () => {
  const {
    getLabel,
    lowerBound,
    operator,
    setLowerBound,
    setOperator,
    setUpperBound,
    upperBound,
  } = useCreateSignalModalStore();

  return (
    <>
      <GridItem>
        <Label>Signal</Label>
      </GridItem>
      <GridItem>
        <Label>Preview</Label>
      </GridItem>

      <GridItem as={Flex} alignItems="center" flexDirection="row" gap="4">
        <Box w="170px">
          <Select
            isMulti={false}
            options={operatorOptions}
            value={operatorOptions.find((option) => option.value === operator)}
            onChange={(newValue) => {
              if (!newValue) {
                return;
              }

              setOperator(newValue.value);
              setLowerBound(null);
              setUpperBound(null);
            }}
          />
        </Box>
        <Flex alignItems="center" direction="row" flex="1" gap="4">
          {operator === 'between' ? (
            <>
              <Input
                onChange={(e) => {
                  const value = e.target.value;
                  setLowerBound(value !== '' ? _.toNumber(value) : null);
                }}
                type="number"
                value={lowerBound ?? ''}
              />
              <Text textColor="kgray.300">and</Text>
              <Input
                onChange={(e) => {
                  const value = e.target.value;
                  setUpperBound(value !== '' ? _.toNumber(value) : null);
                }}
                type="number"
                value={upperBound ?? ''}
              />
            </>
          ) : operator === 'greaterThan' ? (
            <Input
              onChange={(e) => {
                const value = e.target.value;
                setLowerBound(value !== '' ? _.toNumber(value) : null);
              }}
              type="number"
              value={lowerBound ?? ''}
            />
          ) : operator === 'lessThan' ? (
            <Input
              onChange={(e) => {
                const value = e.target.value;
                setUpperBound(value !== '' ? _.toNumber(value) : null);
              }}
              type="number"
              value={upperBound ?? ''}
            />
          ) : (
            assertNever(operator)
          )}
        </Flex>
      </GridItem>
      <GridItem alignSelf="center">{getLabel()}</GridItem>
    </>
  );
};

const DefineSignalStep = ({onClose}: {onClose: () => void}) => {
  const {
    getLabel,
    lowerBound,
    operator,
    selectedField,
    setSignalCategory,
    setStep,
    signalCategory,
    upperBound,
  } = useCreateSignalModalStore();
  const categoryOptions = ScoringCategories.map((category) => ({
    value: category,
    label: ScoringCategoryLabels[category],
  }));

  const disableSave =
    !signalCategory ||
    (operator === 'between' && (!lowerBound || !upperBound)) ||
    (operator === 'greaterThan' && !lowerBound) ||
    (operator === 'lessThan' && !upperBound);

  const createSignalDefinition = useCreateSignalDefinition();
  const saveSignalDefinition = () => {
    const label = getLabel();
    if (!signalCategory || !selectedField || !label) {
      return;
    }

    createSignalDefinition.mutate(
      {
        label,
        category: signalCategory,
        evalFn: {
          type: 'range',
          field: selectedField.id,
          lowerBound: lowerBound ?? undefined,
          upperBound: upperBound ?? undefined,
        },
        type: 'local',
      },
      {onSuccess: onClose}
    );
  };

  return (
    <>
      <ModalHeader fontWeight="normal">
        <Text fontSize="xl" mb="1">
          Define Signals - {selectedField?.label}
        </Text>
        <Text fontSize="sm" textColor="kgray.300">
          Learn more about how to create custom signals{' '}
          <Link
            href="https://docs.keyplay.io/en/"
            isExternal
            textColor="kblue.300"
          >
            here
          </Link>
          .
        </Text>
      </ModalHeader>
      <ModalBody minH="300px">
        <Grid columnGap="8" rowGap="2" templateColumns="60% 1fr">
          <GridItem>
            <Label>Category</Label>
          </GridItem>
          <GridItem />
          <GridItem>
            <Select
              isMulti={false}
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }

                setSignalCategory(newValue.value);
              }}
              options={categoryOptions}
              placeholder="Select a category"
              value={categoryOptions.find(
                ({value}) => value === signalCategory
              )}
            />
          </GridItem>
          <GridItem />
          <GridItem colSpan={2}>
            <Divider my="5" />
          </GridItem>
          <RangeSignalBuilder />
        </Grid>
      </ModalBody>
      <ModalFooter
        borderTop="1px solid"
        borderColor="kgray.200"
        justifyContent="start"
        gap="2"
      >
        <Button
          colorScheme="kbuttonblue"
          fontWeight="normal"
          isDisabled={disableSave}
          isLoading={createSignalDefinition.isLoading}
          onClick={saveSignalDefinition}
        >
          Save Signals
        </Button>
        <Button
          fontWeight="normal"
          onClick={() => setStep('selectField')}
          variant="ghost"
        >
          Back
        </Button>
      </ModalFooter>
    </>
  );
};
